import type { MessageError } from '@/generated/typing';
import type { FieldValues, UseFormSetError } from 'react-hook-form';

const nameErrorServer = 'root.serverError';

interface ValidatorError {
  property: string;
  constraints: string[];
}

const isValidatorError = (error: any): error is ValidatorError => {
  return error.property && error.constraints;
};

export function addServerErrors<T extends FieldValues>(
  errors: MessageError[] | string[] | string,
  setError: UseFormSetError<T>,
) {
  if (!Array.isArray(errors)) {
    setError(nameErrorServer, {
      type: 'server',
      message: errors,
    });
    return;
  }

  if (!errors.length) return;

  errors.forEach((error) => {
    if (typeof error === 'string') {
      setError(nameErrorServer, {
        type: 'server',
        message: error,
      });
    } else if (isValidatorError(error)) {
      setError(nameErrorServer, {
        type: 'server',
        message: error.constraints.join('. '),
      });
    } else {
      setError(nameErrorServer, {
        type: 'server',
        message: error.errors?.join('. '),
      });
    }
  });
}
