const baseStyle = {
  table: {},
  tr: { fontSize: 'md' },
  th: { fontWeight: 500 },
};

const variants = {
  simple: () => ({
    th: {
      '&:first-of-type': {
        borderLeftRadius: 'md',
      },
      '&:last-child': {
        borderRightRadius: 'md',
      },
      color: 'text3',
      py: 3,
      border: 'none',
      borderColor: 'gray.100',
      fontSize: 'xs',
      bg: 'gray.50',
      fontWeight: 600,
    },
    td: {
      _first: {
        fontWeight: 'medium',
      },
      color: 'text1',
      py: 3,
      fontSize: 'md',
      borderBottomWidth: '1px',
      borderColor: 'gray.50',
    },
  }),
};

export default {
  baseStyle,
  variants,
};
