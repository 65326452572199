import useToast from '@/hooks/useToast';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';

export enum ToastQueryEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
}

type RouteQueryToastProps = {
  children: ReactNode;
};

export const RouteQueryToast = ({ children }: RouteQueryToastProps) => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'subscription']);

  const toast = useToast();

  useEffect(() => {
    if (router.query?.toast?.includes(ToastQueryEnum.FAILED)) {
      toast({ title: t('common:encountered_error'), status: 'error' });
    } else if (router.query?.toast?.includes(ToastQueryEnum.SUCCESS)) {
      toast({ title: t('common:feedback_saved_success') });
    }
  }, [router.query?.toast, router?.query, t, toast]);

  return <>{children}</>;
};
