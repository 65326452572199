import { theme } from '@chakra-ui/react';
import colorAlpha from 'color-alpha';

const colors = {
  ...theme.colors,
  red: {
    alpha: colorAlpha('#ef4444', 0.1),
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d',
    '950': '#450a0a',
  },
  blue: {
    '50': '#f0f9ff',
    '100': '#e0f2fe',
    '200': '#bae6fd',
    '300': '#7dd3fc',
    '400': '#38bdf8',
    '500': '#0ea5e9',
    '600': '#0284c7',
    '700': '#0369a1',
    '800': '#075985',
    '900': '#0c4a6e',
    '950': '#082f49',
  },
  amber: {
    '50': '#fff9eb',
    '100': '#feefc7',
    '200': '#fddd8a',
    '300': '#fccb4d',
    '400': '#fbbf24',
    '500': '#f5b40b',
    '600': '#d99e06',
    '700': '#b48409',
    '800': '#926d0e',
    '900': '#785b0f',
    '950': '#453303',
  },

  colors: {
    50: '#FDF3F5',
    100: '#FBE8EB',
    200: '#F8D3DA',
    300: '#F2AEBB',
    400: '#EB8195',
    500: '#E3506C',
    600: '#DB2346',
    700: '#B31D3A',
    800: '#991831',
    900: '#86152B',
    950: '#460B16',
  },
  pink: {
    '50': '#fdf3f4',
    '100': '#fce7ea',
    '200': '#f8d3db',
    '300': '#f2aebb',
    '400': '#ea8298',
    '500': '#de5576',
    '600': '#c9355f',
    '700': '#a92750',
    '800': '#8e2348',
    '900': '#7a2142',
    '950': '#430e21',
  },
  teal: {
    '50': '#f3faf9',
    '100': '#d5f2eb',
    '200': '#b1e6da',
    '300': '#79cfbe',
    '400': '#4db4a4',
    '500': '#34988a',
    '600': '#277a70',
    '700': '#23625b',
    '800': '#204f4a',
    '900': '#1e433f',
    '950': '#0c2725',
  },
  green: {
    '50': '#f0fdf5',
    '100': '#dcfce8',
    '200': '#bbf7d1',
    '300': '#86efad',
    '400': '#4ade81',
    '500': '#22c55e',
    '600': '#16a34a',
    '700': '#15803c',
    '800': '#166533',
    '900': '#14532b',
    '950': '#052e14',
  },
  beige: {
    '50': '#f4f4f2',
    '100': '#e4e3dd',
    '200': '#cbc8bd',
    '300': '#aca898',
    '400': '#958e7a',
    '500': '#7d7665',
    '600': '#726a5c',
    '700': '#5d554b',
    '800': '#504943',
    '900': '#47403c',
    '950': '#272421',
  },
  gray: {
    '50': '#fafaf9',
    '100': '#f5f5f4',
    '200': '#e9e7e6',
    '300': '#d6d3d1',
    '400': '#a8a29e',
    '500': '#78716c',
    '600': '#57534e',
    '700': '#44403c',
    '800': '#292524',
    '900': '#1c1917',
    '950': '#0c0a09',
  },
};

export default colors;
