import api from '@/core/client';
import type { AdminDto, AdminRole, ErrorsDto, StoreDto, UpdateAdminDto } from '@/generated/typing';
import { ActorType } from '@/generated/typing';
import { createGenericContext } from '@/utils/create-generic-context';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { Session } from '@/types/next-auth';
import { useSession } from 'next-auth/react';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { adminHasAccessTo } from '@/utils/access/admin-access';
import type { Feature, Section } from '@/utils/access/user-access';
import useToast from '@/hooks/useToast';
import type { AxiosError } from 'axios';
import useHandleError from '@/hooks/useHandleError';
import { useTranslation } from 'react-i18next';
import { useStores } from '@/hooks/useStores';

type AdminContextType = {
  refetchStores: () => void;
  isAdminActor: boolean;
  loading: boolean;
  activeStore?: StoreDto;
  admin?: AdminDto;
  stores: StoreDto[];
  setActiveStoreId: (storeId: string) => void;
  selectAdminStore: (storeId: string) => void;
  adminRole?: AdminRole;
  hasAdminAccessTo: (item: Feature | Section, specificCondition?: boolean) => boolean;
  updateAdmin: (updateAdminDto: UpdateAdminDto) => void;
};

export const [useAdminContext, AdminContextProvider] = createGenericContext<AdminContextType>();

type Props = {
  children: ReactNode;
};

export const AdminProvider = ({ children }: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data: session } = useSession() as { data: Session | null };
  const { handleError } = useHandleError<UpdateAdminDto>();
  const { t } = useTranslation('common');
  const adminId = useMemo(() => session?.user.id, [session]);
  const isAdminActor = useMemo(() => session?.user.actorType === ActorType.Admin, [session]);
  const isAdminEnabled = useMemo(() => !!adminId && isAdminActor, [adminId, isAdminActor]);

  const { stores, storesLoading, refetchStores, selectStore, activeStoreId, setActiveStoreId } =
    useStores(ActorType.Admin, () => api.stores.getStores({ page: 1 }), isAdminEnabled, adminId);

  const { data: activeStore, isLoading: activeStoreLoading } = useQuery(
    ['activeStore', activeStoreId],
    () => api.stores.getStore(activeStoreId ?? ''),
    {
      enabled: !!activeStoreId && isAdminEnabled,
      onError: () => {
        setActiveStoreId(null);
      },
      keepPreviousData: true,
    },
  );

  const activeStoreFirstFetched = useMemo(
    () =>
      !activeStore &&
      !storesLoading &&
      stores.length &&
      activeStoreLoading &&
      !activeStoreId &&
      isAdminEnabled,
    [activeStore, activeStoreId, activeStoreLoading, isAdminEnabled, stores.length, storesLoading],
  );

  const { data: admin, isLoading: adminLoading } = useQuery(
    ['me'],
    () => api.admins.getAuthenticatedAdmin(),
    {
      enabled: isAdminEnabled,
    },
  );

  const { mutate: updateAdmin } = useMutation(
    async (updateAdminDto: UpdateAdminDto) => {
      const data = await api.admins.updateAdminInfos(adminId ?? '', updateAdminDto);
      return data;
    },
    {
      onSuccess: () => {
        toast({ title: t('feedback_saved_success') });
        queryClient.invalidateQueries(['me']);
      },
      onError: (error: AxiosError<ErrorsDto>) => {
        handleError(error);
      },
    },
  );

  const loading = useMemo(
    () => !admin || storesLoading || adminLoading || activeStoreFirstFetched,
    [admin, storesLoading, adminLoading, activeStoreFirstFetched],
  );

  const adminRole = useMemo(() => admin?.role as AdminRole, [admin?.role]);

  const hasAdminAccessTo = useCallback(
    (item: Feature | Section, specificCondition?: boolean) =>
      isAdminActor && adminHasAccessTo(adminRole, item, specificCondition),
    [adminRole, isAdminActor],
  );

  return (
    <AdminContextProvider
      value={{
        isAdminActor,
        refetchStores,
        admin,
        stores: stores ?? [],
        loading,
        activeStore,
        setActiveStoreId,
        selectAdminStore: selectStore,
        adminRole,
        hasAdminAccessTo,
        updateAdmin,
      }}
    >
      {children}
    </AdminContextProvider>
  );
};
