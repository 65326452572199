import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const globalStyles = {
  global: (props: StyleFunctionProps) => ({
    fontFamily: 'body',
    color: 'text1',
    body: {
      fontSize: 'md',
      color: 'text1',
      fontWeight: 'normal',
      lineHeight: 'base',
      bg: 'secondary.main',
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'gray.700')(props),
    },
    'html,body': {
      height: '100%',
    },
    '#__next, #root': {
      display: 'flex',
      flexDirection: 'column',
      minH: '100%',
    },
  }),
};

export default globalStyles;
