import Color from 'color';
import { useToken } from '@chakra-ui/react';

export const isDark = (color: string) => {
  const lightness = Color(color).lightness();
  // let value = lightness;
  // if (hue >= 45 && hue <= 200 && saturation > 80) {
  //     value += 15;
  // }
  return lightness <= 50;
};

export const useColor = (colorKey: string) => {
  const [color] = useToken('colors', [colorKey]);
  return color;
};
export const useContrastScheme = (colorKey: string) => {
  const color = useColor(colorKey);
  if (isDark(color)) {
    return `whiteAlpha`;
  } else {
    return `blackAlpha`;
  }
};
