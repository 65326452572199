import type { TagDto } from '@/generated/typing';

export const experienceService = {
  isInitialized: () => {
    return !!window.jimo;
  },
  openWidget: () => {
    if (!window.jimo) {
      return;
    }
    window.jimo.push(['do', 'widget:open', []]);
  },
  closeWidget: () => {
    if (!window.jimo) {
      return;
    }
    window.jimo.push(['do', 'widget:close', []]);
  },
  listenWidgetClose: (mode: 'on' | 'off', action: () => void) => {
    if (!window.jimo) {
      return;
    }
    window.jimo.push([mode, 'widget:closed', [action]]);
  },
  login: (storeId: string) => {
    if (!window.jimo) {
      return;
    }
    window.jimo.push(['do', 'identify', [storeId]]);
  },
  updateProductTags: (tags: TagDto[]) => {
    if (!window.jimo) {
      return;
    }

    if (!tags || !tags.length) {
      return;
    }

    const tagsToSend = tags.reduce((prev, curr) => {
      const { name, category } = curr;

      if (!category) return prev;

      if (prev[category.name]) {
        return {
          ...prev,
          [category.name]: `${prev[category.name]};${name}`,
        };
      }

      return {
        ...prev,
        [category.name]: name,
      };
    }, {} as Record<string, string>);

    window.jimo.push(['set', 'user:attributes', [tagsToSend, { refetchBoosted: true }]]);
  },
};
