import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import type { ActorType } from '@/generated/typing';
import { experienceService } from '@/services/experienceService';

export const ACTIVE_STORE_ID_LOCAL_STORAGE_KEY = 'activeStoreId';

export const useStores = (
  actorType: ActorType,
  getStoresApiMethod: () => Promise<any>,
  enabled: boolean,
  actorId?: string,
) => {
  const router = useRouter();
  const [activeStoreId, setActiveStoreId] = useState<string | null>(null);

  const {
    data: stores,
    isInitialLoading: storesLoading,
    refetch: refetchStores,
  } = useQuery([actorType, actorId, 'stores'], () => getStoresApiMethod(), {
    enabled,
    select: (res) => res?.data,
  });

  useEffect(() => {
    if (activeStoreId) {
      experienceService.login(activeStoreId);
      if (enabled) {
        localStorage.setItem(ACTIVE_STORE_ID_LOCAL_STORAGE_KEY, activeStoreId);
      }
    }
  }, [activeStoreId, enabled]);

  useEffect(() => {
    if (!enabled) return;
    if (router.query.storeId && router.query.storeId !== activeStoreId) {
      setActiveStoreId(router.query.storeId as string);
    } else if (!activeStoreId) {
      const storageStoreId = localStorage.getItem(ACTIVE_STORE_ID_LOCAL_STORAGE_KEY);
      if (storageStoreId) {
        setActiveStoreId(storageStoreId);
      } else {
        setActiveStoreId(stores?.[0]?.id || null);
      }
    }
  }, [activeStoreId, setActiveStoreId, stores, enabled, router]);

  const selectStore = useCallback(
    (storeId: string) => {
      if (Object.keys(router.query).length > 1) {
        router.push(`/dashboard?storeId=${storeId}`);
      } else if (router.query.storeId) {
        router.push({ query: { storeId: storeId } });
      } else {
        setActiveStoreId(storeId);
      }
    },
    [router],
  );

  return {
    stores: stores ?? [],
    storesLoading,
    refetchStores,
    selectStore,
    activeStoreId,
    setActiveStoreId,
  };
};
