const baseStyle = {
  ':focus:not(:focus-visible)': {
    boxShadow: 'none',
  },
  fontWeight: 'bold',
  borderRadius: 'md',
};

const variants = {
  solid: ({ colorScheme }: { colorScheme?: string }) => {
    return {
      textDecoration: 'none',
      color: colorScheme === 'gray' ? `gray.500` : `white`,
      bg: colorScheme === 'gray' ? `gray.100` : `${colorScheme}.500`,
      _hover: {
        bgGradient: undefined,
        bg: colorScheme === 'gray' ? `gray.100` : `${colorScheme}.500`,
        textDecoration: 'none',
      },
      _active: {
        bg: colorScheme === 'gray' ? `gray.200` : `${colorScheme}.600`,
      },
    };
  },
  gradient: () => ({
    shadow: 'md',
    color: 'white',
    bg: 'darkGray.500',
    _hover: {
      shadow: 'lg',
      color: 'white',
      bgGradient: 'linear(to-r, pink.300, teal.300)',
    },
  }),
  primary: () => ({
    color: 'white',
    bg: 'darkGray.500',
    shadow: '-3px -3px 10px #b5e0d7, 3px 3px 10px #f2aebb;',
    _hover: {
      shadow: '-5px -5px 10px #b5e0d7, 5px 5px 10px #f2aebb;',
    },
  }),
  outline: ({ colorScheme = 'gray' }: { colorScheme?: string }) => ({
    border: '2px',
    borderColor: `${colorScheme}.500`,
    color: `${colorScheme}.600`,
  }),
  home: () => ({
    color: '#fff',
    background: 'linear-gradient(45deg, rgba(242,174,187,1) 0%, rgba(255,119,146,1) 100%)',
    transition: 'opacity .2s ease-in-out',
    backdropFilter: 'brightness(100%)',
    _hover: {
      opacity: '.9',
    },
    _disabled: {
      _hover: {
        background:
          'linear-gradient(45deg, rgba(242,174,187,1) 0%, rgba(255,119,146,1) 100%) !important',
        opacity: '.2',
        color: 'white !important',
      },
    },
  }),
};

const button = {
  transition: 'all 0.2s',
  baseStyle,
  variants,
  defaultProps: {
    colorScheme: 'gray',
  },
};

export default button;
