import { extendTheme } from '@chakra-ui/react';
import theme from '@/chakra/theme';

import colorAlpha from 'color-alpha';
import Color from 'color';
import { isDark } from '@/chakra/themeUtils';

const generatePalette = (color: string) => {
  let c = Color(color);
  const hue = Color(color).hue();
  const saturation = Color(color).saturationv();

  let isExtraLight = false;

  // Handle Very clear color
  if (saturation > 90) {
    c = c.saturate(-0.3);
  } else if (saturation > 75) {
    c = c.saturate(-0.2);
  }

  if (hue >= 45 && hue <= 200 && saturation > 80) {
    isExtraLight = true;
  }

  return {
    50: c.lightness(isExtraLight ? 96 : 96).hex(),
    100: c.lightness(isExtraLight ? 90 : 90).hex(),
    200: c.lightness(isExtraLight ? 77 : 80).hex(),
    300: c.lightness(isExtraLight ? 67 : 70).hex(),
    400: c.lightness(isExtraLight ? 55 : 60).hex(),
    500: c.lightness(isExtraLight ? 42 : 48).hex(),
    600: c.lightness(isExtraLight ? 36 : 40).hex(),
    700: c.lightness(isExtraLight ? 24 : 30).hex(),
    800: c.lightness(isExtraLight ? 14 : 20).hex(),
    900: c.lightness(isExtraLight ? 8 : 10).hex(),
    950: c.lightness(isExtraLight ? 3 : 5).hex(),
    main: color,
    alpha: colorAlpha(color, 0.1),
    text: getTextColor(color),
    contrastText: getTextContrast(color),
  };
};
const getTextColor = (color: string) => {
  const luminance = Color(color).lightness();
  if (luminance >= 0.5) {
    return Color(color).darken(0.3).hex();
  }
  if (luminance >= 0.4) {
    return Color(color).darken(0.2).hex();
  }
  return color;
};

const getTextContrast = (color: string) => {
  if (isDark(color)) {
    return 'white';
  } else {
    return 'rgba(0, 0, 0, 0.9)';
  }
};

export const buildTheme = (primaryColor?: string, secondaryColor?: string) => {
  secondaryColor = secondaryColor || primaryColor;

  if (!primaryColor || !CSS.supports('color', primaryColor)) {
    primaryColor = '#63c7b5';
  }

  if (!secondaryColor || !CSS.supports('color', secondaryColor)) {
    secondaryColor = '#242d42';
  }

  return extendTheme(theme, {
    colors: {
      primary: generatePalette(primaryColor),
      secondary: generatePalette(secondaryColor),
    },
  });
};
