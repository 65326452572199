import { useTranslation } from '@/hooks/useTranslation';
import { addServerErrors } from '@/utils/form';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import useToast from '@/hooks/useToast';
import { useCallback } from 'react';

export type ErrorMessage = {
  constraints: string[];
};

const useHandleError = <T extends FieldValues>(methods?: UseFormReturn<T, any>) => {
  const toast = useToast();
  const { t, translateError, translationExists } = useTranslation(['common', 'error']);

  const handleError = useCallback(
    (axiosError: any) => {
      let { error, message } = axiosError?.response?.data || {};
      const status = axiosError?.response?.status;

      /* Handle validation constraints errors. */
      if (message && typeof message !== 'string' && message.length) {
        error = message[0]?.constraints?.[0];
        message = error;
      }

      let errorCode = error || message;
      if (errorCode) {
        const parsedErrorCode = errorCode.match(/\s+/) ? errorCode.replace(/\s+/g, '_') : errorCode;

        let hasExistingTranslation = translationExists(parsedErrorCode, 'error');

        let title = undefined;

        if (hasExistingTranslation) {
          title = translateError(parsedErrorCode);
        }

        if (!title) {
          if (status && status >= 400 && status < 500) {
            title = t('error:generic_400');
          } else {
            title = t('error:generic_500');
          }
        }

        let details;
        if (!hasExistingTranslation) {
          details = parsedErrorCode;
        }

        toast({
          title,
          details: details ? JSON.stringify(details, null, 2) : undefined,
          status: 'error',
        });

        if (methods) {
          addServerErrors([error], methods.setError);
        }
      } else {
        toast({ title: t('common:encountered_error'), status: 'error' });
      }

      if (error?.response?.data?.message) {
        const messages: ErrorMessage[] = error.response.data.message;

        messages.forEach((message) => {
          const constraints = message.constraints;

          constraints.forEach((constraint: string) => {
            toast({
              title: translateError(constraint),
              status: 'warning',
            });
          });

          if (methods) {
            addServerErrors(constraints, methods.setError);
          }
        });
      }
    },
    [toast, translateError, methods, t, translationExists],
  );

  return { handleError };
};

export default useHandleError;
