import type * as React from 'react';

import type { NextRouter } from 'next/router';
import { useEffect } from 'react';

export const preventFormDataLossMsg = 'Route change aborted to prevent form data loss.';

export function usePreventFormDataLoss({
  isDirty,
  router,
  isModalOpen,
  setIsModalOpen,
  setUrlToRedirect,
}: {
  isDirty: boolean;
  router: NextRouter;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUrlToRedirect: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  useEffect(() => {
    const nativeBrowserHandler = (event: BeforeUnloadEvent) => {
      if (!isDirty) return;
      event.preventDefault();
    };
    const handleBrowseAway = (url: string) => {
      if (!isDirty) return;
      setUrlToRedirect(url);
      setIsModalOpen(true);
      router.events.emit('routeChangeError');
      throw preventFormDataLossMsg;
    };

    if (!isModalOpen) window.addEventListener('beforeunload', nativeBrowserHandler);

    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', nativeBrowserHandler);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [isModalOpen, isDirty, router.events, setIsModalOpen, setUrlToRedirect]);
}
