import type {
  AdminDto,
  AdminRole,
  DealerDto,
  DealerRole,
  ProviderAccountDto,
  StoreDto,
  UpdateAdminDto,
  UpdateDealerDto,
} from '@/generated/typing';
import { ActorType } from '@/generated/typing';
import { createGenericContext } from '@/utils/create-generic-context';
import type { Feature, Section } from '@/utils/access/user-access';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useAdminContext } from './admin-context';
import { useDealerContext } from './dealer-context';
import { useProviderAccountContext } from './provider-account-context';
import { useSession } from 'next-auth/react';
import type { Session } from '@/types/next-auth';

type UserContextType = {
  fetchUserData: () => void;
  isAdmin: boolean;
  loading: boolean;
  activeStore?: StoreDto;
  selectStore: (storeId: string) => void;
  user?: DealerDto | AdminDto | ProviderAccountDto;
  actorType?: ActorType;
  role?: AdminRole | DealerRole;
  stores: StoreDto[];
  hasAccessTo: (item: Feature | Section, specificCondition?: boolean) => boolean;
  updateUser: (updateUserDto: UpdateAdminDto | UpdateDealerDto) => void;
};

export const [useUserContext, UserContextProvider] = createGenericContext<UserContextType>();

type Props = {
  children: ReactNode;
};

export const UserProvider = ({ children }: Props) => {
  const { data: session } = useSession() as { data: Session | null };
  const actorType = useMemo(() => session?.user.actorType as ActorType, [session]);

  const {
    isAdminActor,
    refetchStores: refetchAdminStores,
    admin,
    stores: adminStores,
    loading: adminLoading,
    activeStore: activeStoreAdmin,
    selectAdminStore,
    adminRole,
    hasAdminAccessTo,
    updateAdmin,
  } = useAdminContext();

  const {
    refetchStores: refetchDealerStores,
    dealer,
    stores: dealerStores,
    loading: dealerLoading,
    activeStore: activeStoreDealer,
    selectDealerStore,
    dealerRole,
    hasDealerAccessTo,
    updateDealer,
  } = useDealerContext();

  const { providerAccount, providerAccountLoading, hasProviderAccountAccessTo } =
    useProviderAccountContext();

  const contextValues = useMemo(
    () => ({
      [ActorType.Admin]: {
        fetchUserData: refetchAdminStores,
        user: admin,
        stores: adminStores,
        selectStore: selectAdminStore,
        activeStore: activeStoreAdmin,
        role: adminRole,
        hasAccessTo: hasAdminAccessTo,
        loading: adminLoading,
        updateUser: updateAdmin,
      },
      [ActorType.Dealer]: {
        fetchUserData: refetchDealerStores,
        user: dealer,
        stores: dealerStores,
        selectStore: selectDealerStore,
        activeStore: activeStoreDealer,
        role: dealerRole,
        hasAccessTo: hasDealerAccessTo,
        loading: dealerLoading,
        updateUser: updateDealer,
      },
      [ActorType.Provider]: {
        fetchUserData: () => {},
        user: providerAccount,
        stores: [],
        selectStore: () => {},
        activeStore: undefined,
        hasAccessTo: hasProviderAccountAccessTo,
        loading: providerAccountLoading,
        updateUser: () => {},
      },
    }),
    [
      activeStoreAdmin,
      activeStoreDealer,
      admin,
      adminLoading,
      adminRole,
      adminStores,
      dealer,
      dealerLoading,
      dealerRole,
      dealerStores,
      hasAdminAccessTo,
      hasDealerAccessTo,
      hasProviderAccountAccessTo,
      providerAccount,
      providerAccountLoading,
      refetchAdminStores,
      refetchDealerStores,
      selectAdminStore,
      selectDealerStore,
      updateAdmin,
      updateDealer,
    ],
  );

  const actorValues = useMemo(() => {
    if (
      actorType === ActorType.Admin ||
      actorType === ActorType.Dealer ||
      actorType === ActorType.Provider
    ) {
      return contextValues[actorType];
    }

    return {
      fetchUserData: () => {},
      user: undefined,
      stores: [],
      selectStore: () => {},
      activeStore: undefined,
      hasAccessTo: () => false,
      loading: false,
      updateUser: () => {},
    };
  }, [actorType, contextValues]);

  const hasAccessTo = useCallback(
    (item: Feature | Section, specificCondition?: boolean) =>
      actorValues.hasAccessTo(item, specificCondition),
    [actorValues],
  );

  const providerValues = useMemo(
    () => ({
      ...actorValues,
      actorType,
      isAdmin: isAdminActor,
      hasAccessTo,
    }),
    [actorValues, actorType, isAdminActor, hasAccessTo],
  );

  return <UserContextProvider value={providerValues}>{children}</UserContextProvider>;
};
