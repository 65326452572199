import getConfig from 'next/config';
import { useMemo } from 'react';

export const useDevEnv = () => {
  const { publicRuntimeConfig } = getConfig();
  const devEnv = useMemo(
    () =>
      publicRuntimeConfig.apiBaseUrl.includes('localhost')
        ? 'local'
        : publicRuntimeConfig.apiBaseUrl.includes('staging')
        ? 'staging'
        : undefined,
    [publicRuntimeConfig],
  );
  return devEnv;
};
